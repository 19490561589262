import { gsap, Sine, Power0, Power1, Power2, Power3, Power4 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import Swiper from 'swiper/bundle';

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger);

import ScrollHint from 'scroll-hint'
import { Fancybox } from "@fancyapps/ui";

// matchMedia
// =======================================================

const mediaQuery_lg = window.matchMedia("(min-width: 1100px)");
const mediaQuery_xl = window.matchMedia("(min-width: 1300px)");
const mediaQuery_md = window.matchMedia("(min-width: 768px)");


// FUNCTION
// =======================================================
function isInPage(node) {
  return (node === document.body) ? false : document.body.contains(node);
}

function closest(node, selector) {
  // IEや一部ブラウザでは.closet()が実装されていないので自前も用意
  return (
    node.closest ||
    function (_selector) {
      do {
        // nodeとselectorがマッチしたら返す
        if ((node.matches || node.msMatchesSelector).call(node, _selector)) {
          return node;
        }
        // マッチしなかったら親要素を代入
        node = node.parentElement || node.parentNode;
      } while (node !== null && node.nodeType === 1);
      return null;
    }
  ).call(node, selector);
}

// CLIP BOARD
// =======================================================

const copyButton = document.querySelector('._js_copy_button')

const message = document.getElementById('message')

if(isInPage(copyButton)) {
  copyButton.onclick = () => {
    const copytext = document.querySelector('.copy_text');
    var text = copytext.innerText;
    navigator.clipboard.writeText(text).then(e => {
      alert('コピーできました');
    });
  };
}


// Fancybox
// =======================================================

Fancybox.bind("[data-fancybox-modal]", {
  hideScrollbar: true,
});

Fancybox.bind("[data-fancybox]", {
  hideScrollbar: true,
  Thumbs: {
    type: "classic",
  },  
  Toolbar: {
    display: {
      left: [],
      middle: [],
      right: ["slideshow","fullscreen", "thumbs", "close"],
    }
  }  
});


// Popup
// =======================================================
document.addEventListener("DOMContentLoaded", () => {
  const popup = document.querySelector(".c-popup");
  if( popup ) {
    const closePopupButton = popup.querySelector(".c-popup__btn");

    // セッションストレージキー
    const popupShownKey = "popupShown";
  
    // セッションストレージをチェック
    const popupShown = sessionStorage.getItem(popupShownKey);
  
    if (!popupShown) {
      // ポップアップを表示
      popup.classList.add("is-show");
  
      // 閉じるボタンの処理
      closePopupButton.addEventListener("click", () => {
        popup.classList.remove("is-show");
        // セッションストレージに記録（セッション中のみ保存）
        sessionStorage.setItem(popupShownKey, "true");
      });
      popup.addEventListener("click", (e) => {
        if(!e.target.closest('.c-popup__box')) {
          popup.classList.remove("is-show");
          sessionStorage.setItem(popupShownKey, "true");
        }
      });
    }
  }
});



// Modal
// =======================================================

const modalTriggers = document.querySelectorAll("[data-modal-trigger]");
const modal = document.querySelectorAll("[data-modal]");
const modalCloseBtns = document.querySelectorAll("[data-modal-close]");
const delayTime = 0.5;
const posY = 40;

modalTriggers.forEach((trigger, i) => {
  trigger.addEventListener("click", function(e){
    e.preventDefault();
    const target = document.getElementById(e.currentTarget.dataset.modalTrigger);
    const targetContent = target.querySelector("[data-modal-content]");
    
    if(e.currentTarget.dataset.modalTrigger == 'modalFacility') {
      const index = e.currentTarget.dataset.slide;
      facilitySwiper.slideTo(index-1);
    }

    gsap.to(target, {
      opacity: 1.2,
      duration: 0.5,
      pointerEvents: "auto",
      ease: Sine.easeOut,
    });
    gsap.to(targetContent, {
      opacity: 1,
      y: 0,
      delay: 0.4,
      duration: 0.6,
      ease: Sine.easeOut,
      onComplete: function () {      
      },
    });
  });
});

modal.forEach((modal, i) => {
  modal.addEventListener("click", function (e) {
    if(!e.target.closest('[data-modal-content]')) {
      modalClose(modal);
    }
  });
});

modalCloseBtns.forEach((btn, i) => {
  btn.addEventListener("click", function (e) {
    let modalParent = closest(e.currentTarget, "[data-modal]");
    modalClose(modalParent);
  });
});

function modalClose(modal) {
  const modalContent = modal.querySelector("[data-modal-content]");
  gsap.to(modalContent, {
    opacity: 0,
    y: 40,
    duration: 0.5,
    ease: Sine.easeOut,
    onComplete: function () {
    },
  });
  gsap.to(modal, {
    opacity: 0,
    duration: 0.5,
    pointerEvents: "none",
    ease: Sine.easeOut,
  });
}

// Accordion
// =======================================================

const accordions = document.querySelectorAll("[data-accordion]");
accordions.forEach( accordion => {
  const trigger = accordion.querySelector('[data-trigger]');
  const content = accordion.querySelector('[data-content]');
  const close = accordion.querySelector('[data-close]');
  trigger.addEventListener('click', function(){
    gsap.to(content, {
      height: (accordion.classList.contains("is-open")) ? 0 : "auto",
      duration: 0.25,
      ease: "power2.inOut"
    });
    trigger.classList.toggle("is-open");
    accordion.classList.toggle("is-open");
  });
  if(close) {
    close.addEventListener('click', function(){
      gsap.to(content, {
        height: (accordion.classList.contains("is-open")) ? 0 : "auto",
        duration: 0.25,
        ease: "power2.inOut"
      });
      trigger.classList.remove("is-open");
      accordion.classList.remove("is-open");
    });
  }
});

// OPEN CAMPUS 
const intrAccordions = document.querySelectorAll("[data-intr-ac]");
const mediaQueryMd = window.matchMedia('(min-width: 768px)')
const mediaQueryLg = window.matchMedia('(min-width: 1100px)')
intrAccordions.forEach( accordion => {
  const intrContent = accordion.querySelector('[data-intr-ac-content]');
  const intrTrigger = accordion.querySelector('[data-intr-ac-trigger] button');

  const hiddenPer_sm = accordion.dataset.hiddenSm;
  const hiddenPer_md = accordion.dataset.hiddenMd;
  const hiddenPer_lg = accordion.dataset.hiddenLg;
  if( mediaQuery_lg.matches ) {
    var hiddenHeight = intrContent.offsetHeight*( hiddenPer_lg/100)+'px';
    intrContent.style.height = hiddenHeight
    accordion.dataset.default = hiddenHeight;
  }
  else if( mediaQuery_md.matches ) {
    var hiddenHeight = intrContent.offsetHeight*( hiddenPer_md/100)+'px';
    intrContent.style.height = hiddenHeight
    accordion.dataset.default = hiddenHeight;
  }
  else {
    var hiddenHeight = intrContent.offsetHeight*( hiddenPer_sm/100)+'px';
    intrContent.style.height = hiddenHeight
    accordion.dataset.default = hiddenHeight;
  }
  intrTrigger.addEventListener('click', function(){
    const parent = closest(this, "[data-intr-ac]");
    const hiddenHeight = parent.dataset.default;
    gsap.to(intrContent, {
      height: (accordion.classList.contains("is-open")) ? hiddenHeight : "auto",
      duration: 0.25,
      ease: "power2.inOut"
    });
    intrTrigger.classList.toggle("is-open");
    
    const btnLabel = intrTrigger.querySelector(".c-btn-label");
    if(intrTrigger.classList.contains("is-open")) {
      btnLabel.textContent = "閉じる";
    }else {
      btnLabel.textContent = intrTrigger.dataset.btnLabel;
    }

    accordion.classList.toggle("is-open");
  });
});


const ctmAccordions = document.querySelectorAll("[data-ctm-accordion]");

ctmAccordions.forEach( accordion => {
  const type = accordion.dataset.ctmAccordion;

  const ctmContent = accordion.querySelector('[data-ctm-content]');
  const ctmTrigger = accordion.querySelector('[data-ctm-trigger]');
  const ctmCloseTrigger = accordion.querySelector('[data-ctm-close]');


  const initHeight_sm = accordion.dataset.heightSm;
  const initHeight_md = accordion.dataset.heightMd;
  const initHeight_lg = accordion.dataset.heightLg;

  if( mediaQuery_lg.matches ) {
    ctmContent.style.height = setAcHight(ctmContent,initHeight_lg);
    accordion.dataset.default = setAcHight(ctmContent,initHeight_lg);
  }
  else if( mediaQuery_md.matches ) {
    ctmContent.style.height = setAcHight(ctmContent,initHeight_md);
    accordion.dataset.default = setAcHight(ctmContent,initHeight_md);
  }
  else {
    ctmContent.style.height = setAcHight(ctmContent,initHeight_sm);
    accordion.dataset.default = setAcHight(ctmContent,initHeight_sm);
  }

  if(ctmTrigger) {
    ctmTrigger.addEventListener('click', function(){
      toggleAccordion(this);
    });
  }

  if(ctmCloseTrigger) {
    ctmCloseTrigger.addEventListener('click', function(){
      toggleAccordion(this);
    });
  }
});

function toggleAccordion(elm) {
  const parent = closest(elm, "[data-ctm-accordion]");
  const childConent = parent.querySelector("[data-ctm-content]");
  const defaultHeight = parent.dataset.default;
  gsap.to(childConent, {
    height: (parent.classList.contains("is-open")) ? defaultHeight : "auto",
    duration: 0.25,
    ease: "power2.inOut"
  });
  parent.classList.toggle("is-open");
}

function setAcHight(elm, val) {
  if(val.includes('px')) {
    return val;
  }
  else {
    if(elm == null) return;
    val = val.replace("%", "");
    return elm.offsetHeight*( val/100)+'px';
  }
}
  

// DRAWER
// =======================================================
const drawer = document.querySelector(".l-drawer");
const drawerOpenBtn = document.querySelector(".l-drawer-button");
const drawerCloseBtn = document.querySelector(".l-drawer .c-btn-close");

const tlDrawer = gsap.timeline({
  paused: true,
  onReverseComplete: function () {},
});

tlDrawer
  .to(drawer, 0.3, {
    autoAlpha: 1,
    pointerEvents: "auto",
    ease: Sine.easeInOut,
  });

tlDrawer.reverse();

// DOM - HEADER
// =======================================================

const originalHeader = document.querySelector(".l-header");
const cloneHeader = originalHeader.cloneNode(true);
const cloneHeaderRow2 = cloneHeader.querySelector(".l-header-row:nth-child(2)");
const cloneNav = cloneHeader.querySelector(".l-nav");
const cloneButtonsGroup2 = cloneHeader.querySelector(".l-header-bottons--group:nth-child(2)");
const cloneMenu = cloneHeader.querySelector(".l-header-menu");

let div = document.createElement('div');
div.classList.add("l-container");
cloneHeaderRow2.appendChild(div);

div.appendChild(cloneNav);
div.appendChild(cloneMenu);

cloneHeader.classList.add("l-header--clone");
document.body.appendChild(cloneHeader);

cloneHeader.onload = cloneHeaderBtn(mediaQuery_xl);
mediaQuery_xl.addEventListener("change", cloneHeaderBtn);
function cloneHeaderBtn(xl) {
  if (xl.matches) {
    div.insertBefore(cloneButtonsGroup2, cloneMenu);
  } else {
    const cloneButtons = cloneHeader.querySelector(".l-header-bottons");
    const cloneButtonsGroup1 = cloneButtons.querySelector(".l-header-bottons--group");
    cloneButtons.insertBefore(cloneButtonsGroup2, cloneButtonsGroup1);
  }
}

// DOM - COVER
// =======================================================

const cover = document.querySelector(".c-cover--type01");
if(isInPage(cover)) {
  cover.onload = moveCoverTitle(mediaQuery_lg);
  mediaQuery_lg.addEventListener("change", moveCoverTitle);
  
  function moveCoverTitle(mediaQuery) {
    const coverInner = cover.querySelector(".c-cover--box-inner");
    const coverTitleBox = document.querySelector(".c-cover-titlebox");
    const insertPos = cover.querySelector(".c-cover--box-row01");
    // console.log(coverInner);
    // console.log(coverTitleBox);
    // console.log(insertPos);
    if(!mediaQuery.matches) {
      coverInner.insertBefore(coverTitleBox,insertPos);
    }
    else {
      cover.parentNode.insertBefore(coverTitleBox,cover.nextElementSibling);
    }
  }
}

// 目次
// =======================================================

const sidebar = document.querySelector('.l-sidebar');
const toc = document.querySelector('[data-toc]');
const tocTrigger = document.querySelector('[data-toc-trigger]');
const tocBg = document.querySelector('.c-toc--bg');
const tocContent = document.querySelector('[data-toc-content]');
const tocLists = document.querySelector('[data-toc-lists]');


const tocOffset = 70;
if(isInPage(toc)) {
  const ankers = document.querySelectorAll("[data-toc-anker]");
  let ul = document.createElement("ul");
  ul.className = "c-toc-lists";
  
  window.addEventListener('load', function(){

    ankers.forEach((anker, i) => {
      let li = document.createElement("li");
      let span = document.createElement("span");
      let ankerLabel = anker.dataset.tocAnker;
      // テキスト変更
      if( ankerLabel != "" ) {
        span.innerHTML = ankerLabel;
      }
      else {
        span.innerHTML = anker.textContent;
      }

      ul.appendChild(li).appendChild(span);


      li.addEventListener('click', () => {
        var sm_offset = 0;
        if(mediaQuery_lg.matches == false) {
          var sm_offset = 40;
        }
        const headerHeight = document.querySelector('.l-header').offsetHeight;
        const ankerPosition = anker.getBoundingClientRect().top + window.pageYOffset - headerHeight-sm_offset;
        gsap.to(window, {
          duration: 1,
          scrollTo: {
            y: ankerPosition,
            autoKill: false
          },
          ease: 'power2'
        });
        sidebar.classList.remove('is-open');
      });      

      if(ankerLabel == "TOP") {
        var ankerArea = anker;
      }
      else {
        var ankerArea = anker.closest(".l-section");
      }

      gsap.to(ankerArea, {
        scrollTrigger: {
          toggleActions: "play none none reverse",
          trigger: ankerArea,
          start: "top center",
          end: "bottom center",
          toggleClass: {
            targets: li, // クラスを切り替える対象の要素
            className: "is-active", // クラス名 "active" を切り替える
          },
        },
      });
    });


    tocLists.appendChild(ul);

    let timer;
    window.addEventListener("resize",function() {
      clearTimeout(timer);
      timer = setTimeout(comp,300);
    });

    function comp() {
      if(mediaQuery_lg.matches == false) {
        var sideBarHeight = toc.offsetHeight;
        var container = document.querySelector('.l-layout_cols2');
        container.style.paddingTop = tocLists.clientHeight+"px";
        console.log(container.style.paddingTop);
      }
    }
    comp();

    
    ScrollTrigger.create({
      trigger: sidebar,
      start: "bottom top",
      end: "bottom+=50 top",
      onEnter: () => {
        sidebar.classList.add("is-scroll");
      },
      onLeaveBack: () => {
        sidebar.classList.remove("is-scroll");
      },
      // once: true,
      // markers: true,
    });

    // const scrollToc = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.l-sidebar',
    //     start: 'top top',
    //     markers: true,
    //     onEnter: () => {
    //       sidebar.classList.add("is-scroll");
    //       // var tlTocClose = gsap.timeline();
    //       // tlTocClose
    //       //   .to(tocLists, { autoAlpha: 0, duration: 0.2 })
    //       //   .to(".c-toc--bg", { autoAlpha: "0", widht: "0%", duration: 0.2 })
    //       //   .to(tocTrigger, { autoAlpha: 1, duration: 0.2 })
    //       //   ;          
    //     },
    //     onLeaveBack: () => {
    //       sidebar.classList.remove("is-scroll");
    //       // var tlTocOpen = gsap.timeline( {
    //       //   onComplete: function(){
    //       //     toc.classList.remove("is-open");
    //       //   }
    //       // } );
    //       // tlTocOpen
    //       //   .to(".c-toc--btn", { autoAlpha: 0, duration: 0 })
    //       //   .to(".c-toc--bg", { autoAlpha: 1, duration: 0.2 })
    //       //   .to(tocLists, { autoAlpha: 1, duration: 0.2 }, "-=0.2")
    //       //   ;          
    //     },
    //   }
    // });    
  });


    
  tocTrigger.addEventListener("click", function(e){
    if(sidebar.classList.contains("is-open")) {
      sidebar.classList.remove('is-open');
      // var tlTocClose = gsap.timeline();
      // tlTocClose
      //   .to(tocLists, { autoAlpha: 0, duration: 0.2 })
      //   .to(".c-toc--bg", { autoAlpha: "0", widht: "0%", duration: 0.2 })
      //   .to(tocTrigger, { autoAlpha: 1, duration: 0.2 })
      //   ;          
    }
    else {
      sidebar.classList.add('is-open');
      // var tlTocOpen = gsap.timeline();
      // tlTocOpen
      //   .to(".c-toc--btn", { autoAlpha: 1, duration: 0 })
      //   .to(".c-toc--bg", { autoAlpha: 1, duration: 0.2 })
      //   .to(tocLists, { autoAlpha: 1, duration: 0.2 }, "-=0.2")
      //   ;          
    }
    
  });
}


//Hash アンカー処理

//querySelectorAllメソッドを使用してページ内のhref属性が#で始まるものを選択
//forEachメソッドを使って、各アンカータグにクリックされた時の処理
document.querySelectorAll('a[href^="#"]:not([data-fancybox-modal])').forEach(anchor => {
  anchor.addEventListener('click', function (e) {

    const cloneHeaderHeight = document.querySelector('.l-header--clone').offsetHeight;

    e.preventDefault();

    const href = anchor.getAttribute('href');
    const target = document.getElementById(href.replace('#', ''));

    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - cloneHeaderHeight - getOffset();

    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: targetPosition,
        autoKill: false
      },
      ease: 'power2'
    });
  });
});



window.addEventListener('load', (event) => {
  const pageHash = decodeURI(location.hash.substring(1));
  if(pageHash) {
    window.setTimeout(function(){
      const scrollTargetElement = document.getElementById(pageHash);
      const cloneHeaderHeight = document.querySelector('.l-header--clone').offsetHeight;
      const ankerPosition = scrollTargetElement.getBoundingClientRect().top + window.pageYOffset - cloneHeaderHeight - getOffset();
      gsap.to(window, {
        duration: 0.8,
        scrollTo: {
          y: ankerPosition,
          autoKill: false
        },
        ease: 'power2'
      });
    }, 800);

  }
});
function getOffset(e) {
  const isToc = document.querySelector('.c-toc');
  var tocOffset;

  // if(mediaQuery_lg.matches == false) {
  //   var layout_cols2 = document.querySelector('.l-layout_cols2');
  //   var toc_offset = parseInt(layout_cols2.style.paddingTop);
  // }
  // else {
  //   var toc_offset = 0;
  // }

  if(isInPage(isToc)) {
    return 70;
  }
  else {
    return 70;
  }
}


// SCORLL TABLE
// =======================================================

window.addEventListener('DOMContentLoaded', function(){
  new ScrollHint('.js-scrollable');
});

// TAB CONTENT
// =======================================================


const tabBtns = document.querySelectorAll("[data-tab-btn]");
const tabContainer = document.querySelector("[data-tab-container]");
const tabs = document.querySelectorAll("[data-tab]");
const voiceLists = document.querySelectorAll(".av-box .lists");

gsap.set(".av-box:not(.is-active) .lists", {autoAlpha: 0,});

tabBtns.forEach( btn => {
  btn.addEventListener('click', function(e){
    e.preventDefault();
    const tabTarget = document.getElementById(e.currentTarget.dataset.tabBtn);
    const lists = tabTarget.querySelectorAll(".lists");

    gsap.to(voiceLists, {
      direction: 0.1,
      autoAlpha: 0,
      ease: "power2.inOut",　//個々の初めと終わりがゆっくり
    });

    tabBtns.forEach( elm => {
      elm.classList.remove("is-active");
    });
    tabs.forEach( tab => {
      tab.classList.remove("is-active");
    });

    e.currentTarget.classList.add("is-active");
    tabTarget.classList.add("is-active");
    
    gsap.to(lists, {
      direction: 0.4,
      autoAlpha: 1,
      stagger: { 
        amount: 1,
      },
      ease: "power2.inOut",
    });
  })
});

// CLICK EVENT
// =======================================================

function tlDrawerFunc() {
  document.body.classList.toggle("is-dr-open");
  tlDrawer.reversed(!tlDrawer.reversed());
}

function subMenuClose(headerNode) {
  const subMenus = headerNode.querySelectorAll(".l-nav--sub");
  subMenus.forEach( menu => {
    gsap.to(menu, { y: "-5%", bottom: "1rem", zIndex: 1, duration: 0.4, ease: Sine.easeOut });  
  });
}

// Drawer
drawerOpenBtn.addEventListener("click", tlDrawerFunc);
drawerCloseBtn.addEventListener("click", tlDrawerFunc);

// Clone
cloneMenu.addEventListener("click", tlDrawerFunc);

// Nav
const navLink = document.querySelectorAll("[data-menu]");

navLink.forEach( elm => {
  elm.addEventListener( "click", (e) => {
    e.preventDefault();
    const menuName = e.currentTarget.dataset.menu;
    const parentHeader = closest(e.currentTarget, ".l-header");
    const targetMenu = parentHeader.querySelector(".l-nav--sub__" + menuName);

    subMenuClose(parentHeader);
    gsap.to(targetMenu, {delay: 0.2, y: "100%", bottom: "0rem", zIndex: 2, duration: 0.4, ease: Sine.easeOut });
  } )
});

// Nav Close
const navClosebtns = document.querySelectorAll(".l-nav--close");
navClosebtns.forEach( btn => {
  btn.addEventListener( "click", (e) => {
    e.preventDefault();
    const parentHeader = closest(e.currentTarget, ".l-header");
    subMenuClose(parentHeader);
  } )
});

document.addEventListener('click', (e) => {
  if(!e.target.closest('.l-header')) {
    subMenuClose(originalHeader);
    subMenuClose(cloneHeader);
  }
})

// Scroll Up
const topBtn = document.querySelector('.c-btn-top');
topBtn.addEventListener("click", function(e){
  e.preventDefault();
  gsap.to(window, { duration: 0.3, scrollTo: { y: 0, autoKill: false}, ease: 'power2' });
});

const topBtnSp = document.querySelector('.c-float-btn-top');
topBtnSp.addEventListener("click", function(e){
  e.preventDefault();
  gsap.to(window, { duration: 0.3, scrollTo: { y: 0, autoKill: false}, ease: 'power2' });
});


// DOM - DRAWER
// =======================================================

const pickup = document.querySelector('.l-drawer-pickup');
const gsearch = document.querySelector('.u-gsearch');


window.onload = handle_md(mediaQuery_md);

mediaQuery_md.addEventListener("change", handle_md);

function handle_md(md) {
  if (md.matches) {
    const gsearchParentNode = document.querySelector('.l-drawer-column__right');
    const pickupParentNode = document.querySelector('.l-drawer-footer .l-drawer-container');
    const gsearchPos = document.querySelector('.l-drawer-column__right .l-drawer-flex');

    gsearchParentNode.insertBefore(gsearch, gsearchPos);
    pickupParentNode.insertBefore(pickup, null);
  } else {
    const gsearchParentNode = document.querySelector('.l-drawer-column__right');
    const pickupParentNode = document.querySelector('.l-drawer-column__right .l-drawer-flex');
    const gsearchPos = document.querySelector('.u-listsns');
    const picPos =  document.querySelector('.u-listlinks--cols2');
    
    gsearchParentNode.insertBefore(gsearch, gsearchPos);
    pickupParentNode.insertBefore(pickup, picPos.nextSibling);
  }
}

// SWIPER
// =======================================================

const mySwiper = new Swiper('.l-drawer-carousel', {
  slidesPerView: 'auto',
  loop: true, // ループ有効
  speed: 5000, // ループの時間
  allowTouchMove: false, // スワイプ無効
  autoplay: {
    delay: 0, // 途切れなくループ
  },
});


const kv = document.querySelector(".l-home-kv--img");
if(isInPage(kv)) {
  const kvSwiper = new Swiper(kv, {
    slidesPerView: "auto",
    loop: true,
    speed: 1500,
    effect: 'fade',
    autoplay: {
        delay: 2000,
    },
    breakpoints: {
      // 768: {
      //   slidesPerView: 3,
      // },
      // 1100: {
      //   slidesPerView: 4,
      // },
    },
  });
}

const peopleSlider = document.querySelector(".people-slider");
if(isInPage(peopleSlider)) {
  const peopleSliderWrap = peopleSlider.querySelector(".swiper-wrapper");


  for(let i=peopleSliderWrap.children.length;i>=0;i--){
    if(peopleSliderWrap.children.length != i){
      peopleSliderWrap.appendChild(peopleSliderWrap.children[Math.random()*i|0]);
    }
    if( i == 0 ) {
      const peopleSwioer = new Swiper(peopleSlider, {
        slidesPerView: 'auto',
        loop: true,
        speed: 1200,
        effect: 'fade',
        autoplay: {
          delay: 3000,
        },
        navigation: {
          nextEl: '.people-slider-button--next',
          prevEl: '.people-slider-button--prev',
        },
      });
    }
  }
}


const pickupCarousel = document.querySelector(".pick-carousel");
const pickupCarouselPagination =document.querySelector(".pick-carousel__pagination");

// スライド遅延時間を設定（初期化後に取得可能）
let slideDelay;

// ページネーションの進捗バーを更新する関数
const updatePaginationProgress = (swiper) => {
  const bullets = document.querySelectorAll('.pick-carousel__pagination .swiper-pagination-bullet');
  bullets.forEach((bullet, index) => {
    const progressBar = bullet.querySelector('.progress-bar');
    if (index === swiper.realIndex) {
      // 現在のスライドの進捗バーをアニメーションさせる
      progressBar.style.transition = `transform ${slideDelay}ms linear`;
      progressBar.style.transform = 'scaleX(1)';
    } else {
      // 他のスライドの進捗バーをリセット
      progressBar.style.transition = '';
      progressBar.style.transform = 'scaleX(0)';
    }
  });
};

// ページネーションの進捗バーをリセットする関数
const resetPaginationProgress = (swiper) => {
  const bullets = document.querySelectorAll('.pick-carousel__pagination .swiper-pagination-bullet');
  bullets.forEach((bullet) => {
    const progressBar = bullet.querySelector('.progress-bar');
    progressBar.style.transition = '';
    progressBar.style.transform = 'scaleX(0)';
  });
};

// Swiperの初期化
const pickSwiper = new Swiper(pickupCarousel, {
  centeredSlides: true,
  slidesPerView: 1,
  loop: true,
  loopAdditionalSlides: 1,
  speed: 1000,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  grabCursor: true,
  watchSlidesProgress: true,
  pagination: {
    el: '.pick-carousel__pagination', // ページネーションの要素を指定
    clickable: true,                 // ページネーションをクリック可能に
    renderBullet: (index, className) => {
      // ページネーションのカスタムHTMLを生成
      return `
        <span class="${className}">
          <span class="progress-bar"></span>
        </span>`;
    },
  },
  on: {
    init: (swiper) => {
      // Swiperが初期化されたときの処理
      slideDelay = swiper.params.autoplay.delay; // スライド遅延時間を取得
      updatePaginationProgress(swiper);
    },
    slideChangeTransitionStart: (swiper) => {
      // スライド変更時に進捗バーをリセット
      resetPaginationProgress(swiper);
    },
    slideChangeTransitionEnd: (swiper) => {
      // スライド変更後に進捗バーを再設定
      updatePaginationProgress(swiper);
    },
  },
  breakpoints: {
    600: {
      slidesPerView: 3,
    }
  },
});



const loopCarousel = new Swiper('.loop-carousel', {
  loop: true,
  spaceBetween: 12,
  autoplay: {
    delay: 0,
  },
  speed: 8000,
  slidesPerView: 2,
  breakpoints: {
    768: {
      slidesPerView: 3,
    },
    1100: {
      slidesPerView: 4,
      spaceBetween: 24,
    },
  },
})

const facilitySwiper = new Swiper('.facility-slider', {
  loop: true,
  centeredSlides: false,
  slidesPerView: "auto",
  navigation: {
    nextEl: '.facility-button-next',
    prevEl: '.facility-button-prev',
  },
  
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  
});


// SCROLL TRIGGER
// =======================================================

// Float Header
const floatHeader = gsap.timeline({
  scrollTrigger: {
    trigger: originalHeader,
    start: originalHeader.offsetHeight + ' top',
    // markers: true,
    onEnter: () => {
      gsap.to(cloneHeader, { y: "0%", duration: 0.4, ease: Sine.easeOut });
      subMenuClose( originalHeader );
    },
    onLeaveBack: () => {
      gsap.to(cloneHeader, { y: "-105%", duration: 0.4, ease: Sine.easeOut });
      subMenuClose( cloneHeader );
    },
  }
});

// Go Top
if(topBtn != null) {
  const fadeInTopBtn = gsap.timeline({
    scrollTrigger: {
      trigger: 'body',
      start: innerHeight/2 + ' top',
      onEnter: () => {
        gsap.to(topBtn, { autoAlpha: 1, duration: 0.4, ease: Sine.easeOut });
      },
      onLeaveBack: () => {
        gsap.to(topBtn, { autoAlpha: 0, duration: 0.4, ease: Sine.easeOut });
      },
    }
  });
}
